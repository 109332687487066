import React, { useEffect, useState } from "react";

const Archive = () => {
  const [webArchives, setWebArchives] = useState([]);
  const [mediaUrls, setMediaUrls] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWebArchives = async () => {
      try {
        const response = await fetch(
          "https://blog.juanfalibene.com/wp-json/wp/v2/archivo?per_page=34"
        );

        if (!response.ok) {
          throw new Error("ERROR: on fetch data");
        }

        const data = await response.json();
        // Ordenar los archivos web por 'acf.anio' de mayor a menor
        data.sort((a, b) => b.acf.anio - a.acf.anio);

        setWebArchives(data);

        // Fetch media for each archive item
        const mediaPromises = data.map(async (item) => {
          if (item.featured_media) {
            const mediaResponse = await fetch(
              `https://blog.juanfalibene.com/wp-json/wp/v2/media/${item.featured_media}`
            );

            if (mediaResponse.ok) {
              const mediaData = await mediaResponse.json();
              return { id: item.id, mediaUrl: mediaData.source_url };
            } else {
              console.log("ERROR: on fetch media");
              return { id: item.id, mediaUrl: null };
            }
          } else {
            return { id: item.id, mediaUrl: null };
          }
        });

        const mediaResults = await Promise.all(mediaPromises);
        const mediaUrlsMap = mediaResults.reduce((acc, { id, mediaUrl }) => {
          acc[id] = mediaUrl;
          return acc;
        }, {});
        setMediaUrls(mediaUrlsMap);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.log("ERROR ON LOAD WEB ARCHIVES", error);
      }
    };
    fetchWebArchives();
  }, []);

  return (
    <div>
      {loading ? (
        <p className='loading'>Cargando...</p> // Puedes reemplazar esto con un spinner u otra animación de carga
      ) : (
        webArchives.map((webArchive, index) => (
          <a
            href={webArchive.acf.url}
            className='archive-container'
            target='_blank'
            rel='noopener noreferrer'
            key={webArchive.id}
          >
            <div className='archive-item'>
              <h2 className='archive-text'>
                <span className='archive-number'>0{index + 1}</span>
                {webArchive.title.rendered}
                <span className='archive-year'>{webArchive.acf.anio}</span>
              </h2>
              {mediaUrls[webArchive.id] && (
                <img
                  src={mediaUrls[webArchive.id]}
                  alt={webArchive.title.rendered}
                  className='img-one'
                />
              )}
            </div>
          </a>
        ))
      )}
    </div>
  );
};

export default Archive;
